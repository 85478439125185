import React, { Component } from "react";
import "../assets/css/belly.css";
import Animate from "react-smooth";
import photo1 from "../assets/buik/1.png";
import photo2 from "../assets/buik/2.png";
import photo3 from "../assets/buik/3.png";
import photo4 from "../assets/buik/4.png";
import photo5 from "../assets/buik/5.png";
import photo6 from "../assets/buik/6.png";

class Belly extends Component {
  state = {};

  photos = [
    photo1,
    photo2,
    photo3,
    photo4,
    photo5,
    photo6,
  ];

  done = index => {
    if (index === this.photos.length - 1) {
      this.props.onAnimated();
    }
  };

  render() {
    return (
      <div className="bellies">
        {this.photos.map((photo, index) => (
          <Animate
            begin={index * 750}
            from="0"
            to="1"
            duration="1500"
            attributeName="opacity"
            onAnimationEnd={() => this.done(index)}
          >
            <div className="belliesContainer">
              <div className="belly">
                <img src={photo} alt="Foto" />
              </div>
            </div>
          </Animate>
        ))}
      </div>
    );
  }
}

export default Belly;

import React, { useState, useEffect, useRef } from "react";
import Animate from "react-smooth";
import TimeAgo from "./components/timeAgo";
import Belly from "./components/belly";
import Pictures from "./components/pictures";
import hearth from "./assets/heart.svg";
import background from "./assets/bg.jpg";
import "./App.css";
import picture1 from "./assets/pictures/1.jpg";
import picture2 from "./assets/pictures/2.jpg";
import picture3 from "./assets/pictures/3.jpg";
import picture4 from "./assets/pictures/4.jpg";
import picture5 from "./assets/pictures/5.jpg";
import picture6 from "./assets/pictures/6.jpg";
import belly1 from "./assets/buik/1.png";
import belly2 from "./assets/buik/2.png";
import belly3 from "./assets/buik/3.png";
import belly4 from "./assets/buik/4.png";
import belly5 from "./assets/buik/5.png";
import belly6 from "./assets/buik/6.png";

const App = () => {
  const [time, setTime] = useState('00:00')
  const [bellyTime, setBellyTime] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);

  const name = "Juliëtte";
  const nameOther = "Neeltje Daan Roet";
  const birthTime = "21:09";
  const birthDate = new Date(2022, 2, 14, 21, 9, 0);
  const slogan = "Van liefde komt niets dan lief"

  const timeInput = useRef(null);

  const delay = ms => {
    return {
      style: {},
      duration: ms
    };
  };

  const steps = {
    privacy: [
      { style: { opacity: 0 } },
      {
        style: { opacity: 1, transform: "translate3d(0, -20px, 0)" },
        duration: 1000
      }
    ],

    form: [
      { style: { opacity: 1 } },
      {
        style: { opacity: 0, transform: "translate3d(0, -100px, 0)" },
        duration: 1000
      }
    ],

    question: [
      { style: { opacity: 0 } },
      {
        style: { opacity: 1, transform: "translate3d(0, -20px, 0)" },
        duration: 2000
      }
    ],

    time: [
      { style: { opacity: 0, transform: "translate3d(0, 20px, 0)" } },
      {
        style: { opacity: 1 },
        duration: 2000
      }
    ],
    pictures: [
      { style: { opacity: 0, transform: "translate3d(0, 40px, 0)" } },
      {
        style: { opacity: 1, transform: "translate3d(0, 0, 0)" },
        duration: 2000
      }
    ],
    name: [
      {
        style: {
          opacity: 0
        }
      },
      {
        style: {
          opacity: 1
        },
        duration: 2000
      },
      delay(500),
      {
        style: {
          transform: "translate3d(0, -100px, 0)"
        },
        duration: 1000
      }
    ],
    opacity: ms => {
      return [
        {
          style: {
            opacity: 0
          }
        },
        {
          style: {
            opacity: 1
          },
          duration: ms
        }
      ];
    }
  };

  const handleBellyAnimated = () => {
    setAuthenticated(true);
  };

  const handleChangeTime = (event) => {
    setTime(event.target.value);
  }

  useEffect( () => {
    setTimeout(() => {
      if (time === birthTime) {
        setBellyTime(true);

        timeInput.current.blur();
      }
    }, 100);
  }, [time])

    return (
      <div className="App">
        <link rel="preload" href={picture1} as="image" />
        <link rel="preload" href={picture2} as="image" />
        <link rel="preload" href={picture3} as="image" />
        <link rel="preload" href={picture4} as="image" />
        <link rel="preload" href={picture5} as="image" />
        <link rel="preload" href={picture6} as="image" />
        <link rel="preload" href={belly1} as="image" />
        <link rel="preload" href={belly2} as="image" />
        <link rel="preload" href={belly3} as="image" />
        <link rel="preload" href={belly4} as="image" />
        <link rel="preload" href={belly5} as="image" />
        <link rel="preload" href={belly6} as="image" />
        {authenticated && (
          <div className="back">
            <div className="name">{name}</div>
            <Animate begin="1000" steps={steps.opacity(1000)}>
              <div className="nameBirth">{nameOther}</div>
            </Animate>
            <Animate begin="2000" steps={steps.opacity(3000)}>
              <div className="all">
              <img src={hearth} alt="hartje" />
                <span>
                 {slogan}
                </span>
                
              </div>
            </Animate>
            <Animate begin="3000" steps={steps.opacity(2000)}>
              <div className="ago">
                <TimeAgo name={name} date={birthDate} />
              </div>
            </Animate>
            <Animate begin="4000" steps={steps.pictures}>
              <div>
                <Pictures />
              </div>
            </Animate>
          </div>
        )}

        <Animate
          canBegin={authenticated}
          attributeName="opacity"
          from="1"
          to="0"
        >
          <div className="front" style={{ backgroundImage: `url(${background})` }}>
            <div className="center">
              {bellyTime && <Belly onAnimated={handleBellyAnimated} />}
              <Animate canBegin={bellyTime} steps={steps.form}>
                <div>
                  <Animate steps={steps.name}>
                    <div className="name">{name}</div>
                  </Animate>
                  <Animate begin="3000" steps={steps.privacy}>
                    <div className="privacy">Voor wat privacy...</div>
                  </Animate>
                  <Animate begin="3500" steps={steps.question}>
                    <div className="question">Hoe laat is ze geboren?</div>
                  </Animate>
                  <Animate begin="4500" steps={steps.time}>
                    <div>
                      <input
                        ref={timeInput}
                        type="time"
                        className="time"
                        value={time}
                        onInput={handleChangeTime}
                        onChange={handleChangeTime}
                        placeholder="00:00"
                      />
                    </div>
                  </Animate>
                </div>
              </Animate>
            </div>
          </div>
        </Animate>
      </div>
    );
  }

export default App;

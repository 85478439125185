import React from "react";
import photo1 from "../assets/pictures/1.jpg";
import photo2 from "../assets/pictures/2.jpg";
import photo3 from "../assets/pictures/3.jpg";
import photo4 from "../assets/pictures/4.jpg";
import photo5 from "../assets/pictures/5.jpg";
import photo6 from "../assets/pictures/6.jpg";
import "../assets/css/pictures.css";

const Pictures = () => {
  return (
    <div className="pictures">
      <ul>
        <li>
          <img src={photo1} alt="Foto" />
        </li>
        <li>
          <img src={photo2} alt="Foto" />
        </li>
        <li>
          <img src={photo3} alt="Foto" />
        </li>
        <li>
          <img src={photo4} alt="Foto" />
        </li>
        <li>
          <img src={photo5} alt="Foto" />
        </li>
        <li>
          <img src={photo6} alt="Foto" />
        </li>
        <li>
          Gemaakt met <span>&hearts;</span> in De Goorn
        </li>
      </ul>
    </div>
  );
};

export default Pictures;
